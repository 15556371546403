<template>
	<dialog-component @close="closeDialog">
		<template #header-title>
			{{ $t('verification.need') }}
		</template>
		<p class="status-dialog-text">
			{{ $t('verification.description') }}
		</p>
		<ButtonComponent @click="startVerification">
			{{ $t('verification.start') }}
		</ButtonComponent>
	</dialog-component>
</template>

<script>
import { mapActions } from 'pinia'
import { useModalStore } from '@/stores/modal'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

export default {
	name: 'GirlVerificationNeedsDialog',
	components: { ButtonComponent, DialogComponent },
	methods: {
		...mapActions(useModalStore, ['openVerificationDialog']),
		startVerification() {
			this.$emit('close-dialog')
			this.openVerificationDialog()
		},
		closeDialog() {
			this.$emit('close-dialog')
		}
	}
}
</script>
