import { defineStore } from 'pinia'
import { useFollowingApiStore } from '@/stores/consumer/following/api'
import { useHomeStore } from '@/stores/home'
import { useSearchStore } from '@/stores/search'
import { useGirlStore } from '@/stores/girl'
import { useConsumerStore } from '@/stores/consumer'
import posts from '@/stores/helper'

export const useFollowingStore = defineStore('followingStore', {
	state: () => ({
		apiStore: useFollowingApiStore(),
		homeStore: useHomeStore(),
		searchStore: useSearchStore(),
		girlStore: useGirlStore(),
		consumerStore: useConsumerStore(),
		girlId: null
	}),
	actions: {
		setGirlId(id) {
			this.girlId = id
		},
		resetGirlId() {
			this.girlId = null
		},
		postsFollowingStateToStart() {
			posts.changePostsFollowingStateToStart(this.homeStore.posts, this.girlId)
			posts.changePostsFollowingStateToStart(this.searchStore.posts, this.girlId)
			posts.changePostsFollowingStateToStart(this.searchStore.recommendedPosts, this.girlId)
			posts.changePostsFollowingStateToStart(this.girlStore.posts, this.girlId)
			posts.changePostsFollowingStateToStart(this.consumerStore.awards, this.girlId)
			posts.changePostsFollowingStateToStart(this.consumerStore.likedPosts, this.girlId)
		},
		postsFollowingStateToStop() {
			posts.changePostsFollowingStateToStop(this.homeStore.posts, this.girlId)
			posts.changePostsFollowingStateToStop(this.searchStore.posts, this.girlId)
			posts.changePostsFollowingStateToStop(this.searchStore.recommendedPosts, this.girlId)
			posts.changePostsFollowingStateToStop(this.girlStore.posts, this.girlId)
			posts.changePostsFollowingStateToStop(this.consumerStore.awards, this.girlId)
			posts.changePostsFollowingStateToStop(this.consumerStore.likedPosts, this.girlId)
		},
		async startFollowing(girlId) {
			try {
				await this.apiStore.startFollowing(girlId)
				this.postsFollowingStateToStart()
			} catch (e) {
				throw new Error()
			}
		},
		async stopFollowing() {
			try {
				await this.apiStore.stopFollowing(this.girlId)
				this.postsFollowingStateToStop()
			} catch (e) {
				throw new Error()
			}
		}
	}
})
