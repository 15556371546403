import { mapActions, mapState } from 'pinia'
import { useGirlStore } from '@/stores/girl'
import { useHomeStore } from '@/stores/home'
import { useConsumerStore } from '@/stores/consumer'
import { useSearchStore } from '@/stores/search'
import { useProfileStore } from '@/stores/profile'
import { useFollowingStore } from '@/stores/consumer/following'
import { usePostStore } from '@/stores/post'

export default {
	computed: {
		...mapState(useGirlStore, {
			girlProfile: 'profile'
		}),
		...mapState(useConsumerStore, {
			consumerProfile: 'profile'
		}),
		$_actingConsumerIsFollower() {
			return this?.girlProfile?.acting_consumer_is_follower
		},
		$_actingConsumerIsSubscribed() {
			return this?.girlProfile?.acting_consumer_is_subscribed
		}
	},
	methods: {
		...mapActions(useProfileStore, {
			getFollowingGirls: 'getFollowingGirls'
		}),
		...mapActions(useGirlStore, {
			applyBlurToGirlPostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
			turnOnWaitingSubscriptionForGirlPost: 'turnOnWaitingSubscription',
			toggleSubscriptionState: 'toggleSubscriptionState',
			toggleFollowingState: 'toggleFollowingState',
			incrementTotalFollowers: 'incrementTotalFollowers',
			decrementTotalFollowers: 'decrementTotalFollowers',
			incrementTotalFans: 'incrementTotalFans',
			decrementTotalFans: 'decrementTotalFans',
			unsubscribe: 'unsubscribe'
		}),
		...mapActions(useHomeStore, {
			resetFilter: 'resetFilter',
			applyBlurToHomePostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
			turnOnWaitingSubscriptionForHomePost: 'turnOnWaitingSubscription'
		}),
		...mapActions(useConsumerStore, {
			applyBlurToConsumerPostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
			turnOnWaitingSubscriptionForConsumerPost: 'turnOnWaitingSubscription'
		}),
		...mapActions(useSearchStore, {
			applyBlurToSearchPostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
			turnOnWaitingSubscriptionForSearchPost: 'turnOnWaitingSubscription'
		}),
		...mapActions(useFollowingStore, {
			startFollowing: 'startFollowing',
			stopFollowing: 'stopFollowing'
		}),
		...mapActions(usePostStore, {
			togglePostFollowingState: 'toggleFollowingState'
		}),
		$_changePostsData(girlId) {
			this.applyBlurToGirlPostsOnlyForFans(girlId)
			this.applyBlurToHomePostsOnlyForFans(girlId)
			this.applyBlurToConsumerPostsOnlyForFans(girlId)
			this.applyBlurToSearchPostsOnlyForFans(girlId)
			this.turnOnWaitingSubscriptionForGirlPost(girlId)
			this.turnOnWaitingSubscriptionForHomePost(girlId)
			this.turnOnWaitingSubscriptionForConsumerPost(girlId)
			this.turnOnWaitingSubscriptionForSearchPost(girlId)
		},
		async $_startFollowing(girlId) {
			await this.startFollowing(girlId)
			if (!this.$_actingConsumerIsSubscribed) {
				this.incrementTotalFollowers()
			}
			this.toggleFollowingState()
			this.togglePostFollowingState()
		},
		async $_stopPaidSubscription(girlId) {
			await this.unsubscribe(girlId)
			this.$_changePostsData(girlId)
			await this.getFollowingGirls()
			await this.resetFilter()
			if (this.$config.allowFollowing) {
				if (this.$_actingConsumerIsFollower) {
					this.decrementTotalFans()
					this.incrementTotalFollowers()
					this.toggleSubscriptionState()
				}
				return
			}
			this.decrementTotalFans()
			this.toggleSubscriptionState()
		},
		async $_stopFreeSubscription() {
			await this.stopFollowing()
			await this.getFollowingGirls()
			await this.resetFilter()
			if (this.$_actingConsumerIsFollower && !this.$_actingConsumerIsSubscribed) {
				this.decrementTotalFollowers()
				this.toggleFollowingState()
			}
		},
		async $_stopPaidAndFreeSubscription(girlId) {
			await this.stopFollowing()
			await this.unsubscribe(girlId)
			this.$_changePostsData(girlId)
			await this.getFollowingGirls()
			await this.resetFilter()

			this.decrementTotalFans()
			this.toggleSubscriptionState()
			this.toggleFollowingState()
		}
	}
}
