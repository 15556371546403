import Vue from 'vue'
import { extend } from 'vee-validate'
import {
	required,
	email,
	confirmed,
	min,
	max,
	ext,
	min_value,
	max_value,
	regex,
	between
} from 'vee-validate/dist/rules'
import digitsOfNumber from '@/helpers/digitsOfNumber'
import AppConfig from '@/config.class'
import heic2any from 'heic2any'
import isHeic from './isHeic'

window.addEventListener('DOMContentLoaded', () => {
	function getImageSizes(file) {
		const URL = window.URL || window.webkitURL
		return new Promise((resolve) => {
			const image = new Image()
			image.onerror = () => resolve(false)
			image.onload = () => resolve(image)
			if (isHeic(file[0])) {
				heic2any({
					blob: file[0],
					toType: 'image/jpeg'
				}).then((res) => {
					image.src = URL.createObjectURL(new Blob([res]))
				})
			} else {
				image.src = URL.createObjectURL(new Blob(file))
			}
		})
	}

	const requiredTexts = {
		email: Vue.prototype.i18n.t('error.enterEmail'),
		password: Vue.prototype.i18n.t('error.enterEmail'),
		passwordConfirmation: Vue.prototype.i18n.t('error.repeatPassword'),
		gesture: Vue.prototype.i18n.t('error.gesture'),
		photoWithPassport: Vue.prototype.i18n.t('error.passport'),
		nickname: Vue.prototype.i18n.t('error.name'),
		account_number: Vue.prototype.i18n.t('error.card'),
		secret_number: Vue.prototype.i18n.t('error.card'),
		price: Vue.prototype.i18n.t('error.priceRange', { min: 99, max: 10000 }),
		files: Vue.prototype.i18n.t('error.file'),
		listName: Vue.prototype.i18n.t('consumersList.enterName'),
		'main-text': Vue.prototype.i18n.t('error.emptyField')
	}

	const emailTexts = {
		email: Vue.prototype.i18n.t('error.incorrectEmail')
	}

	const betweenTexts = {
		price: (minPrice, maxPrice) => Vue.prototype.i18n.t('error.priceRange', { min: minPrice, max: maxPrice })
	}

	const regexTexts = {
		account_number: Vue.prototype.i18n.t('error.incorrectNumber'),
		secret_number: Vue.prototype.i18n.t('error.incorrectNumber')
	}

	const passwordTexts = {
		password: Vue.prototype.i18n.t('error.passwordRules')
	}

	const confirmedTexts = {
		passwordConfirmation: Vue.prototype.i18n.t('error.passwordConfirm')
	}

	const minTexts = {
		nickname: (length) => Vue.prototype.i18n.t('error.nicknameMin', { length }),
		about_yourself: (length) => Vue.prototype.i18n.t('error.aboutMin', { length })
	}

	const maxTexts = {
		nickname: (length) => Vue.prototype.i18n.t('error.nicknameMax', { length }),
		about_yourself: (length) => Vue.prototype.i18n.t('error.aboutMax', { length }),
		cardName: (length) => Vue.prototype.i18n.t('error.cardMax', { length }),
		listName: (length) => Vue.prototype.i18n.t('error.listMax', { length })
	}

	const minValueTexts = {
		amount: (value) => Vue.prototype.i18n.t('error.amountMin', { count: digitsOfNumber(value) })
	}

	const maxValueTexts = {
		amount: () => Vue.prototype.i18n.t('error.passwordConfirm')
	}

	extend('required', {
		...required,
		message: (field) => requiredTexts[field]
	})

	extend('regex', {
		...regex,
		message: (field) => regexTexts[field]
	})

	extend('email', {
		...email,
		message: (field) => emailTexts[field]
	})

	extend('password', {
		validate: (value) => /^[a-zA-Z0-9]+$/.test(value),
		message: passwordTexts.password
	})

	extend('confirmed', {
		...confirmed,
		message: (field) => confirmedTexts[field]
	})

	extend('minDimensions', {
		validate: async (file, [width, height]) => {
			if (file[0].type.includes('video')) return true
			const image = await getImageSizes(file)
			return image.width >= width && image.height >= height
		},
		message: (file, params) => {
			const width = params[0]
			const height = params[1]
			return Vue.prototype.i18n.t('error.photoSizes', { width, height })
		}
	})

	extend('noOnlySpaces', {
		validate: (string) => /.*\S.*/gm.test(string),
		message: Vue.prototype.i18n.t('error.symbol')
	})

	extend('aspectRatio', {
		validate: async (file) => {
			if (file[0].type.includes('video')) return true
			const { width, height } = await getImageSizes(file)
			return Math.max(width, height) / Math.min(width, height) < 3
		},
		message: Vue.prototype.i18n.t('error.photoAspect')
	})

	extend('size', {
		validate: async (file, size) => {
			if (file[0].type.includes('video')) return true
			const fileSize = file[0].size
			return fileSize < size
		},
		message: () => {
			const maxSize = Number(AppConfig.maxImgSize)
			return Vue.prototype.i18n.t('error.photoBig', { size: maxSize / 1000000 })
		}
	})

	extend('min', {
		...min,
		message: (field, { length }) => minTexts[field](length)
	})

	extend('max', {
		...max,
		message: (field, { length }) => maxTexts[field](length)
	})

	extend('min_value', {
		...min_value,
		message: (field, { minValue }) => minValueTexts[field](minValue)
	})

	extend('max_value', {
		...max_value,
		message: (field, { maxValue }) => maxValueTexts[field](maxValue)
	})

	extend('balance', {
		...max_value,
		message: () => Vue.prototype.i18n.t('error.noFunds')
	})

	extend('imageExt', {
		...ext,
		message: () => Vue.prototype.i18n.t('error.format', { format: 'JPEG/JPG, PNG, HEIC, HEIF, MP4, MOV' })
	})

	extend('noLinksOrEmail', {
		validate: (string) =>
			!(
				/((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))\S){2,})|((?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))\S){2,})\.[a-z]{1,3}\b)+)/gim.test(
					string
				) || string.includes('@')
			),
		message: Vue.prototype.i18n.t('error.email')
	})

	extend('between', {
		...between,
		// eslint-disable-next-line no-shadow
		message: (field, { min, max }) => betweenTexts[field](min, max)
	})
})
