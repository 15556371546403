<template>
	<div>
		<dialog-component v-if="verificationStatus === 'needs'" @close="closeDialog">
			<template #header-title>
				{{ $t('verification.need') }}
			</template>
			<p class="status-dialog-text">
				{{ $t('verification.description') }}
			</p>
			<ButtonComponent @click="startVerification">
				{{ $t('verification.start') }}
			</ButtonComponent>
		</dialog-component>
		<dialog-component v-if="verificationStatus === 'in_process'" @close="closeDialog">
			<template #header-title> {{ $t('verification.title') }}. {{ $t('verification.almostDone') }}. </template>
			<p class="status-dialog-text">
				{{ $t('verification.waitLittle') }}
			</p>
			<ButtonComponent :loading="isSending" @click="closeDialog">
				{{ $t('ok') }}
			</ButtonComponent>
		</dialog-component>
		<dialog-component v-if="verificationStatus === 'rejected'" @close="closeDialog">
			<template #header-title>
				{{ $t('verification.need') }}
			</template>
			<p class="status-dialog-text">
				{{ $t('verification.needRetry') }}
			</p>
			<ButtonComponent :loading="isSending" @click="startVerification">
				{{ $t('verification.retry') }}
			</ButtonComponent>
		</dialog-component>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useModalStore } from '@/stores/modal'
import { useGirlProfileStore } from '@/stores/girlProfile'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

export default {
	name: 'GirlVerificationStatusDialog',
	components: { ButtonComponent, DialogComponent },
	data() {
		return {
			isSending: false
		}
	},
	computed: {
		...mapState(useGirlProfileStore, { verificationStatus: 'verification_status' })
	},
	methods: {
		...mapActions(useModalStore, { openVerificationDialog: 'openVerificationDialog' }),
		startVerification() {
			this.$emit('close')
			this.openVerificationDialog()
		},
		closeDialog() {
			this.$emit('close')
		}
	}
}
</script>
