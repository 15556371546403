<template>
	<span class="custom-checkbox">
		<input
			hidden
			type="checkbox"
			v-bind="$attrs"
			:value="value"
			v-model="values"
			@input="$emit('input', $event.target.value)"
		/>
	</span>
</template>

<script>
export default {
	name: 'CustomCheckbox',
	model: {
		prop: 'checked',
		event: 'change'
	},
	props: {
		checked: {
			type: null,
			required: false,
			default: false
		},
		value: {
			type: null,
			required: false,
			default: false
		}
	},
	computed: {
		values: {
			get() {
				return this.checked
			},
			set(value) {
				this.$emit('change', value)
			}
		}
	}
}
</script>

<style scoped lang="scss">
.custom-checkbox {
	width: 20px;
	height: 20px;
	min-width: 20px;
	background: $color-black-secondary;
	border-radius: 4px;
	position: relative;

	&:has(input:checked)::after,
	&.inverse::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' fill='none'%3E%3Cpath stroke='%23BEBEC9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M13 1 4.75 9 1 5.364'/%3E%3C/svg%3E");
		background-size: 12px auto;
		background-repeat: no-repeat;
		background-position: center;
	}

	&.inverse:has(input:checked)::after {
		display: none;
	}
}
</style>
