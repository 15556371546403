<template>
	<confirmation-dialog
		class="confirm-unfollow-dialog"
		btn-accept-text="Да, отписаться"
		btn-decline-text="Нет"
		:is-sending="isProcessing"
		@close="onClose"
		@accept="onAccept"
		@decline="onDecline"
	>
		<template #title>Вы хотите отписаться?</template>
		Тогда вы не сможете видеть новые посты модели и не будете получать взаимность
	</confirmation-dialog>
</template>

<script>
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue'
import { mapActions, mapState } from 'pinia'
import { useGirlStore } from '@/stores/girl'
import { useModalStore } from '@/stores/modal'
import { useFollowingStore } from '@/stores/consumer/following/index'
import subscriptionsMixin from '@/mixins/subscriptionsMixin'

export default {
	name: 'ConfirmUnfollowDialog',
	components: { ConfirmationDialog },
	mixins: [subscriptionsMixin],
	data() {
		return {
			isProcessing: false
		}
	},
	computed: {
		...mapState(useGirlStore, { profile: 'profile' })
	},
	methods: {
		...mapActions(useFollowingStore, {
			resetGirlId: 'resetGirlId',
			setGirlId: 'setGirlId'
		}),
		...mapActions(useModalStore, {
			closeUnfollowDialog: 'closeUnfollowDialog'
		}),
		async onAccept() {
			try {
				this.isProcessing = true
				if (this.profile) this.setGirlId(this.profile.id)
				await this.$_stopFreeSubscription()
				this.onClose()
			} finally {
				this.isProcessing = false
			}
		},
		onDecline() {
			this.onClose()
		},
		onClose() {
			this.closeUnfollowDialog()
			this.resetGirlId()
		}
	}
}
</script>

<style scoped lang="scss">
.confirm-unfollow-dialog {
	&::v-deep .dialog-body {
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
	}
}
</style>
