<template>
	<div class="main-content">
		<div v-if="$auth.check('consumer')" class="history flex row start container">
			<template v-if="!followingGirlsHasLoaded">
				<placeholder-filter-card v-for="i in 3" :key="`pfc${i}`" />
			</template>
			<filter-card-button v-if="showFilterCardButton" @click="onRedirect" />
			<filter-card v-for="girl in followingGirls?.data" :key="`fg${girl.id}`" v-bind="girl" />
		</div>
		<div class="feed flex column start">
			<infinite-scroll :load-function="getMorePosts" :show-p-w-a="true" save-scroll>
				<placeholder-post v-if="!postsHasLoaded" />
				<post-component v-for="post in posts?.data" :key="`fp${post.id}`" v-bind="post" />
			</infinite-scroll>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useHomeStore } from '@/stores/home'
import { useProfileStore } from '@/stores/profile'
import InfiniteScroll from '@/components/InfiniteScroll.vue'
import PostComponent from '@/components/PostComponent.vue'
import FilterCard from '@/components/FilterCard.vue'
import PlaceholderPost from '@/components/placeholders/PlaceholderPost.vue'
import PlaceholderFilterCard from '@/components/placeholders/PlaceholderFilterCard.vue'
import FilterCardButton from '@/components/FilterCardButton.vue'

export default {
	name: 'HomeView',
	components: {
		FilterCardButton,
		PlaceholderFilterCard,
		PlaceholderPost,
		FilterCard,
		PostComponent,
		InfiniteScroll
	},
	computed: {
		...mapState(useProfileStore, [
			'followingGirls',
			'followingGirlsHasLoaded',
			'has_subscription_to_service',
			'hasCardPayment'
		]),
		...mapState(useHomeStore, ['posts', 'postsHasLoaded']),
		showFilterCardButton() {
			return this.followingGirlsHasLoaded && !this.has_subscription_to_service
		},
		isConsumer() {
			return this.$auth.check('consumer')
		}
	},
	methods: {
		...mapActions(useHomeStore, ['getPosts', 'getMorePosts']),
		...mapActions(useProfileStore, ['getSubscriptionTypes']),
		onRedirect() {
			window.location.href = this.$config.subsCardUrl
		}
	},
	async mounted() {
		if (!this.posts) {
			this.getPosts()
		}
		if (this.isConsumer) {
			await this.getSubscriptionTypes()
		}
	}
}
</script>
