<template>
	<dialog-component @close="closeDialog">
		<template #header-title>
			{{ $t('greetingsText') }}
		</template>
		<div class="greeting-text">
			<textarea-component
				id="description"
				v-model="greetingText"
				class="create-post-description"
				:placeholder="$t('greetingsExample')"
			/>
		</div>
		<div class="greeting-button">
			<ButtonComponent :loading="isSending" @click="onClickSave">
				{{ $t('save') }}
			</ButtonComponent>
		</div>
	</dialog-component>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useReferralStore } from '@/stores/referral'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import TextareaComponent from '@/components/forms/TextareaComponent.vue'

export default {
	name: 'LinkGreetingsDialog',
	components: { TextareaComponent, ButtonComponent, DialogComponent },
	data() {
		return {
			greetingText: '',
			isSending: false
		}
	},
	watch: {
		greetingText(val) {
			if (val.length > 200) {
				this.greetingText = val.slice(0, 200)
			}
		}
	},
	computed: {
		...mapState(useReferralStore, { greeting: 'greeting', linkId: 'linkId' })
	},
	methods: {
		...mapActions(useReferralStore, { changeGreetings: 'changeGreetings' }),
		async onClickSave() {
			this.isSending = true
			await this.changeGreetings(this.linkId, this.greetingText)
				.catch()
				.finally(() => {
					this.isSending = false
					this.$emit('close')
				})
		},
		closeDialog() {
			this.$emit('close')
		}
	},
	created() {
		this.greetingText = this.greeting
	}
}
</script>
<style lang="scss" scoped>
@media (min-width: $screen-desktop) {
	.greeting-button {
		margin-top: 24px;
		text-align: center;

		.btn {
			max-width: 220px;
			margin: 0 auto;
		}
	}

	::v-deep {
		.greeting-text {
			.form-field {
				min-height: 158px;
				line-height: 20px;
				color: $color-gray-dark-additional;
			}
		}
	}
}
</style>
