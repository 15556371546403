<script lang="jsx">
export default {
	name: 'ButtonComponent',
	functional: true,
	props: {
		tag: {
			type: String,
			default: () => 'button'
		},
		color: {
			type: String,
			default: () => 'primary'
		},
		className: {
			type: [String, Array],
			default: () => ''
		},
		shrink: {
			type: Boolean,
			default: () => false
		},
		small: {
			type: Boolean,
			default: () => false
		},
		type: {
			type: String,
			default: () => 'button'
		},
		disabled: {
			type: Boolean,
			default: () => false
		},
		loading: {
			type: Boolean,
			default: () => false
		},
		to: {
			type: [String, Object],
			default: ''
		},
		for: {
			type: String,
			default: ''
		},
		href: {
			type: String,
			default: ''
		}
	},
	render(h, { props, slots, listeners }) {
		const Tag = props.tag
		const classList = ['btn', `btn-${props.color}`, props.className]
		if (props.shrink) {
			classList.push('btn-shrink')
		}
		if (props.small) {
			classList.push('btn-small')
		}
		const clickHandler = () => {
			if (props.disabled || props.loading) {
				return null
			}
			return listeners.click ? listeners.click() : null
		}
		return (
			<Tag
				type={props.type}
				to={props.to}
				for={props.for}
				href={props.href}
				class={classList}
				disabled={props.disabled || props.loading}
				onClick={clickHandler}
			>
				{props.loading ? <i class="i-spinner rotating" /> : slots().default}
			</Tag>
		)
	}
}
</script>
<style lang="scss">
.btn {
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px 10px;
	gap: 10px;
	height: 52px;
	border-radius: 12px;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	cursor: pointer;
	border: none;
	font-family: inherit;
	color: $color-white;

	@media (min-width: $screen-desktop) {
		height: 44px;
		padding-left: 18px;
		padding-right: 18px;
		transition: $transition-color, $transition-background, $transition-border;
	}
}

.btn-white {
	background: $color-white;
	color: $color-black;
	&:hover {
		color: $color-black-hover;
	}
	&:active {
		background-color: $color-white-active;
		color: $color-black;
	}
}

.btn-transparent {
	border: 1px solid $color-black-third;
	background: transparent;
	&:hover {
		border-color: $color-black-third-hover;
	}
	&:active {
		color: $color-white-active;
		border-color: $color-black-third-active;
	}
}
.btn-blank {
	color: $color-black-third;
	border: 1px solid transparent;
	background: transparent;
}

.btn-primary-outline {
	color: $color-magenta-secondary;
	border: 1px solid $color-magenta-secondary;
	background: transparent;
	&:hover {
		color: $color-magenta-secondary-hover;
	}
	&:active {
		color: $color-magenta-secondary-active;
	}
}

.btn-secondary-outline {
	color: $color-white;
	border: 1px solid $color-semi-transparent;
	background: transparent;
}

.btn-transparent:disabled {
	color: $color-gray-disabled;
}

.btn-primary {
	background: $color-magenta;
	&:hover {
		background-color: $color-magenta-hover;
	}
	&:active {
		background-color: $color-magenta-active;
	}
}

.btn-blue {
	background: linear-gradient(299.84deg, rgba(14, 63, 189, 0.5) 0%, rgba(75, 120, 235, 0.5) 97.47%);
}

.btn-primary:disabled {
	background: transparent;
	border: 1px solid $color-gray-disabled;
	box-sizing: border-box;
	color: $color-gray-disabled;
}

.btn-secondary {
	background: $color-black-secondary;
}

.btn-yellow {
	background: $color-yellow;
	color: $color-black-fourth;
}

.btn-shrink {
	width: auto;
}

.btn-small {
	font-weight: 500;
	padding: 8px 20px;
	height: 40px;
}

.btn-disabled {
	background-color: rgba($color-white, 0.3);
	color: rgba($color-white, 0.3);
	cursor: default;
}
</style>
