import { confirmAge } from '@/http/consumer/confirmAge'
import { getNotifications, getMoreNotifications } from './notifications'
import { enableSmsNotification, disableSmsNotification } from './smsNotification'
import { sendComplaint } from './complaints'
import { getProfile } from './profile'
import { consumerStartWaitingSubscription } from './startWaitingSubscription'
import { getReceipts, getMoreReceipts } from './receipts'
import { getSubscriptions, getMoreSubscriptions } from './subscriptions'
import { unsubscribeFromService } from './unsubscribeFromService'
import { getAwards, getMoreAwards } from './awards'
import { getComments, getMoreComments } from './comments'
import { getConsumer, getConsumerByNickname } from './consumer'
import { getFollowingGirls } from './girls'
import { getPostsWithTotalConsumerLikes, getMorePostsWithTotalConsumerLikes } from './postsWithLikes'
import { getGirlsWithTotalLikesByPosts, getMoreGirlsWithTotalLikesByPosts } from './girlsWithLikes'
import { getLikedPosts, getMoreLikedPosts } from './likes'
import { updateProfile, loadAvatar, loadCover } from './updateProfile'

export default {
	getFollowingGirls,
	getAwards,
	getMoreAwards,
	getComments,
	getMoreComments,
	getConsumer,
	getConsumerByNickname,
	getPostsWithTotalConsumerLikes,
	getMorePostsWithTotalConsumerLikes,
	getGirlsWithTotalLikesByPosts,
	getMoreGirlsWithTotalLikesByPosts,
	getLikedPosts,
	getMoreLikedPosts,
	getNotifications,
	getMoreNotifications,
	updateProfile,
	loadAvatar,
	loadCover,
	enableSmsNotification,
	disableSmsNotification,
	sendComplaint,
	getProfile,
	consumerStartWaitingSubscription,
	getReceipts,
	getMoreReceipts,
	getSubscriptions,
	getMoreSubscriptions,
	unsubscribeFromService,
	confirmAge
}

export { follow, unfollow } from './following'
