<template>
	<dialog-component
		:class="['calendar-dialog', { 'dialog-week-mode': weekMode }]"
		:title-left="true"
		@close="$emit('close')"
		@click="closeTimePicker"
	>
		<template #header-title>
			{{ $t('post.publicationDate') }}
		</template>
		<div class="calendar-wrap">
			<div>
				<div class="fields-group">
					<div
						:class="['fields-group-input', { active: datePickerActive, error: showErrors && !currentDate }]"
						@click="toggleDatePicker"
					>
						<span v-if="currentDate">{{ currentDate | moment('DD.MM.YYYY') }}</span>
						<svg-icon name="calendar" size="24px" />
					</div>
					<div
						:class="['fields-group-input input-time', { active: timePickerActive, error: showErrors && !currentTime }]"
						@click="toggleTimePicker"
					>
						<span v-if="currentTime">{{ currentTime }}</span>
						<svg-icon name="clock" size="24px" />
					</div>
				</div>
				<Calendar
					:rows="1"
					:step="1"
					ref="calendar"
					class="new-post-calendar"
					:attributes="attributes"
					:selected-day="startDay"
					@day-click="pickDate"
					@exit-week-mode="removeWeekMode"
					@open-week-mode="openWeekMode"
					:min-date="minDate"
					:max-date="maxDate"
					:week-mode="isMobileDevice && weekMode"
					:week-number="weekNumber"
					:current-day="currentDate"
					:create-new-post="true"
					:posts-count="postsCount"
					@click="closeTimePicker"
				/>
				<div v-if="weekMode" @click="closeTimePicker" class="schedule-detail-new-post">
					<div class="schedule-current-date">
						{{ getFullDate(day) }}
					</div>
				</div>
				<time-picker-component
					ref="timePicker"
					v-if="timePickerActive"
					@update="timerUpdate"
					:min-date-hour="minDateHour"
					:current-day="currentDay"
				/>
			</div>

			<schedule-day-events
				v-if="!isMobileDevice || weekMode"
				:show-more="false"
				:schedule-by-day="scheduleByDay"
				:is-loading="isLoading"
				:selected-day="currentDate ? getFullDate(day) : ''"
				@click="closeTimePicker"
			/>
		</div>
		<template #dialog-footer>
			<button-component @click="submitCalendarDialog">
				{{ $t('complete') }}
			</button-component>
		</template>
	</dialog-component>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import TimePickerComponent from '@/components/forms/TimePickerComponent.vue'
import Calendar from '@/components/Calendar.vue'
import ScheduleDayEvents from '@/components/schedule/ScheduleDayEvents.vue'
import { useGirlSchedule } from '@/stores/girlSchedule'
import { useGirlProfileStore } from '@/stores/girlProfile'
import { useCreatePostStore } from '@/stores/createPost'
import ButtonComponent from '@/components/ButtonComponent.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { useCommonStore } from '@/stores/common'

export default {
	name: 'CalendarDialog',
	components: { DialogComponent, TimePickerComponent, Calendar, ScheduleDayEvents, ButtonComponent, SvgIcon },
	props: {
		scheduledPostsEnabled: {
			type: Boolean,
			default: false
		},
		shouldBePublishedAt: {
			type: String,
			default: ''
		},
		isEditing: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			timePickerActive: false,
			datePickerActive: true,
			showErrors: false,
			currentTime: null,
			currentDate: null,
			day: null,
			weekMode: false,
			postsCount: null,
			weekNumber: '',
			attributes: [
				{
					key: 'pick',
					highlight: {
						class: 'vc-highlight-pick'
					},
					dates: ''
				}
			],
			startDay: '',
			SvgIcon
		}
	},
	computed: {
		...mapState(useGirlProfileStore, ['scheduled_posts']),
		...mapState(useCreatePostStore, ['dateFromCalendar']),
		...mapState(useGirlSchedule, ['schedulesList', 'scheduleByDay', 'isLoading']),
		...mapState(useCommonStore, ['isMobileDevice']),
		currentDay() {
			return this.$moment(this.day).format('YYYY-MM-DD') === this.$moment().format('YYYY-MM-DD')
		},
		minDate() {
			const nowDate = new Date()
			const futureDate = new Date(nowDate.getTime() + this.scheduled_posts.range * 60 * 60 * 1000)
			if (nowDate.getDate() !== futureDate.getDate()) {
				return futureDate.toString()
			}
			return nowDate.toString()
		},
		maxDate() {
			return this.schedulesList?.end_date
		},
		minDateHour() {
			if (!this.currentDate || this.selectedDateIsClose(this.currentDate)) {
				let newDate = new Date()
				newDate = new Date(newDate.getTime() + this.scheduled_posts.min_delay * 1000).getHours() + 1
				return newDate !== 24 ? newDate : 0
			}
			return 0
		}
	},
	methods: {
		...mapActions(useGirlSchedule, ['getSchedulesList', 'getScheduleByDay', 'resetScheduleByDay']),
		async openWeekMode() {
			this.weekMode = true
			const dayFormat = this.$moment(this.day).format('YYYY-MM-DD')
			await this.getScheduleByDay(dayFormat)
		},
		removeWeekMode() {
			this.weekMode = false
		},
		closeTimePicker() {
			this.timePickerActive = false
		},
		toggleTimePicker() {
			this.datePickerActive = false
			this.timePickerActive = true
		},
		toggleDatePicker() {
			this.timePickerActive = false
			this.datePickerActive = true
		},
		submitCalendarDialog() {
			if (this.currentTime && this.currentDate) {
				this.$emit('close')
			} else this.showErrors = true
		},
		selectedDateIsClose(secondDate) {
			const diffInMilliseconds = secondDate.getTime() - new Date().getTime()
			const DelayInMilSeconds = this.scheduled_posts.min_delay * 1000
			return diffInMilliseconds <= DelayInMilSeconds
		},
		getWeekNumber(date) {
			const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
			const daysOffset = firstDayOfMonth.getDay()
			const firstFullWeekStart = 1 + ((7 - daysOffset) % 7)
			const day = date.getDate()
			const weekNumber = Math.ceil((day - firstFullWeekStart) / 7) + 1
			return String(weekNumber)
		},
		addZeroToNumber(number) {
			return String(number).padStart(2, '0')
		},
		async pickDate(day) {
			if (day.isDisabled) {
				return
			}
			if (this.day && this.day.getTime() === day.date.getTime()) {
				return
			}
			const chooseDate = this.$moment(day.date).toDate()
			this.currentDate = chooseDate
			this.postsCount = day.attributes[0]?.customData?.postsCount
			this.attributes[0].dates = day.date
			this.weekNumber = String(day.week)
			this.day = day.date
			this.setPublicDate()
			this.removeUnvalidTime()
			if (this.weekMode || !this.isMobileDevice) {
				if (this.postsCount) {
					await this.getScheduleByDay(day.id)
				} else {
					this.resetScheduleByDay()
				}
			}
		},
		removeUnvalidTime() {
			if (this.selectedDateIsClose(this.currentDate)) {
				this.currentTime = null
				this.datePublic = null
			}
		},
		setPublicDate() {
			if (this.currentTime && this.currentDate) {
				this.$emit('setPublicDate', { currentTime: this.currentTime, day: this.day })
			}
		},
		removeDatePublic() {
			this.currentTime = null
			this.currentDate = null
			this.startDay = ''
			this.selectedDay = null
			this.attributes[0].dates = null
			this.weekMode = false
			this.day = false
			this.showErrors = false
			this.$refs.calendar.removeDate()
		},
		getFullDate(day) {
			return new Intl.DateTimeFormat('ru-RU', {
				weekday: 'long',
				year: 'numeric',
				month: 'long',
				day: 'numeric'
			}).format(new Date(day))
		},
		setCurrentDate(momentDay, newPost) {
			let newDay
			if (this.selectedDateIsClose(momentDay.toDate()) && newPost) {
				newDay = this.$moment().add(this.scheduled_posts.min_delay / 3600 + 1, 'hours')
			}
			const hour = this.addZeroToNumber(newDay ? newDay.hour() : momentDay.hour())
			const minutes = this.addZeroToNumber(newDay ? 0 : momentDay.minutes())
			this.currentDate = newDay
				? newDay.set({ hour: 0, minutes: 0 }).toDate()
				: momentDay.set({ hour: 0, minutes: 0 }).toDate()
			this.currentTime = `${hour}:${minutes}`
			this.day = this.currentDate
			this.setPublicDate()
			this.startDay = this.currentDate.toString()
			this.weekMode = true
			this.weekNumber = this.getWeekNumber(this.day)
			this.getScheduleByDay(this.$moment(this.currentDate).format('YYYY-MM-DD'))
		},
		timerUpdate(time) {
			if (time) {
				this.currentTime = time
				this.setPublicDate()
			}
		},
		convertScheduleToCalendar() {
			for (let i = 0; i < this.schedulesList?.items?.length; i += 1) {
				this.attributes.push({
					key: this.schedulesList.items[i].date,
					dot: !!this.schedulesList.items[i].scheduled_posts_count,
					bar: !!this.schedulesList.items[i].rejected_posts_count,
					dates: this.schedulesList.items[i].date,
					customData: {
						postsCount: this.schedulesList.items[i].scheduled_posts_count
					}
				})
			}
			this.attributes.push({
				key: 'today',
				highlight: {
					class: 'vc-highlight-today'
				},
				dates: new Date()
			})
		}
	},
	async beforeMount() {
		if (this.scheduledPostsEnabled) {
			await this.getSchedulesList()
			this.convertScheduleToCalendar()
			if (!this.isEditing && this.dateFromCalendar) {
				this.setCurrentDate(this.$moment(this.dateFromCalendar), true)
			}
			if (this.isEditing && this.shouldBePublishedAt) {
				this.setCurrentDate(this.$moment(this.shouldBePublishedAt))
			}
		}
	},
	async beforeDestroy() {
		if (this.scheduledPostsEnabled) {
			this.resetScheduleByDay()
		}
	}
}
</script>

<style lang="scss" scoped>
.calendar-dialog::v-deep {
	@media (min-width: $screen-desktop) {
		.calendar-wrap {
			display: grid;
			grid-template-columns: 48% 48%;
			justify-content: space-between;
			height: 100%;
		}
		.dialog-header {
			padding: 20px 0 15px 0 !important;
		}
		.dialog-title {
			display: block !important;
			position: static !important;
			width: 50% !important;
			margin: 0 !important;
		}
		.dialog {
			display: flex;
			flex-direction: column;
			max-width: 740px;
			height: 100%;
			max-height: 590px;
		}
		& .dialog-body {
			height: 100%;
			flex-direction: column;
			padding-bottom: 0;
		}
		& .button-week-mode {
			display: none;
		}
		& .dialog-footer {
			position: absolute;
			bottom: 15px;
			left: 0;
			right: 0;
			width: 50%;
			margin-top: auto;
			padding-top: 0;
			& .btn {
				width: 220px;
				margin: 0 auto;
			}
		}
		& .new-post-calendar {
			height: 100%;
		}
		.schedule-list {
			overflow-y: auto;
			max-height: 456px;
		}
		& .schedule-item-time {
			display: none;
			&-desktop {
				display: block;
			}
		}
		& .none {
			padding: 10px 0;
		}
		.time-picker-container {
			top: 105px;
			right: auto;
			left: 215px;
		}
	}
	& .btn {
		position: relative;
		bottom: 0;
	}
	& .schedule-current-date {
		font-size: 14px;
		text-align: center;
		color: $color-semi-transparent;
		border-bottom: 1px solid rgba($color-black-additional-third, 0.5);
		padding: 4px 0 10px 0;
		@media (min-width: $screen-desktop) {
			display: none;
		}
	}
	& .schedule-current-date:first-letter {
		text-transform: uppercase;
	}
	& .total {
		text-align: center;
		margin-top: 5px;
	}
}
.dialog-week-mode::v-deep {
	& .dialog {
		height: calc(100% - 60px);
		display: flex;
		flex-direction: column;
		padding-bottom: 0;
	}
}

.fields-group {
	display: flex;
	align-items: center;
	@media (min-width: $screen-desktop) {
		margin-bottom: 10px;
	}
	& + .datepicker-container {
		margin-top: 16px;
	}
}
.fields-group-input {
	flex: 1;
	height: 41px;
	background: $color-black-secondary;
	border: 1px solid $color-black-additional-third;
	transition:
		border-color 0.2s ease-in-out,
		background-color 0.2s ease-in-out,
		color 0.2s ease-in-out;
	font-size: 16px;
	border-radius: 12px;
	position: relative;
	display: flex;
	align-items: center;
	padding: 5px 14px;
	&.active {
		border-color: $color-white;
	}
	&.error {
		border-color: $color-red-additional;
		& svg {
			color: $color-red-additional;
		}
	}
	& svg {
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.input-time {
	margin-left: 12px;
	max-width: 111px;
	font-variant-numeric: tabular-nums;
	cursor: pointer;
}
</style>
