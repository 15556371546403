<template>
	<div class="consumer-tutorial tutorial">
		<div class="consumer-tutorial-slider">
			<div class="consumer-tutorial-close" @click="closeTutorial">
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M6.99974 5.58672L11.9497 0.636719L13.3637 2.05072L8.41374 7.00072L13.3637 11.9507L11.9497 13.3647L6.99974 8.41472L2.04974 13.3647L0.635742 11.9507L5.58574 7.00072L0.635742 2.05072L2.04974 0.636719L6.99974 5.58672Z"
						fill="#727281"
					/>
				</svg>
			</div>
			<div ref="swiper" class="swiper">
				<div class="swiper-wrapper">
					<div class="swiper-slide consumer-tutorial-slide">
						<div class="consumer-tutorial-text"><strong>MyMood</strong> - {{ $t('tutorial.perfectPlace') }}}</div>
						<div class="consumer-tutorial-img-wrap">
							<div class="consumer-tutorial-img-bg" />
							<img
								:src="require('@/assets/images/consumer-tutorial/img-1.png')"
								alt="img-1"
								class="consumer-tutorial-img img-1"
							/>
						</div>
					</div>
					<div class="swiper-slide consumer-tutorial-slide">
						<div class="consumer-tutorial-text">
							{{ $t('tutorial.subscribeAndReceive') }}
						</div>
						<div class="consumer-tutorial-img-wrap">
							<img
								:src="require('@/assets/images/consumer-tutorial/img-2.png')"
								alt="img-2"
								class="consumer-tutorial-img img-2"
							/>
						</div>
					</div>
					<div class="swiper-slide consumer-tutorial-slide">
						<div class="consumer-tutorial-text">
							{{ $t('tutorial.ratePosts') }}
						</div>
						<div class="consumer-tutorial-img-wrap">
							<img
								:src="require('@/assets/images/consumer-tutorial/img-3.png')"
								alt="img-3"
								class="consumer-tutorial-img img-2"
							/>
						</div>
					</div>
					<div v-if="messagesSlide" class="swiper-slide consumer-tutorial-slide">
						<div class="consumer-tutorial-text">{{ $t('tutorial.chatInChatRooms') }} <strong>MyMood</strong></div>
						<div class="consumer-tutorial-img-wrap">
							<div class="consumer-tutorial-img-bg" />
							<img
								:src="require('@/assets/images/consumer-tutorial/img-4.png')"
								alt="img-4"
								class="consumer-tutorial-img img-1"
							/>
						</div>
					</div>
					<div class="swiper-slide consumer-tutorial-slide">
						<div class="consumer-tutorial-img-wrap">
							<div class="consumer-tutorial-img-bg" />
							<img
								:src="require('@/assets/images/consumer-tutorial/img-5.png')"
								alt="img-5"
								class="consumer-tutorial-img img-1"
							/>
						</div>
						<div class="consumer-tutorial-btn" @click="endTutorial">{{ $t('tutorial.begin') }}</div>
					</div>
				</div>
				<div class="swiper-controls">
					<div class="swiper-pagination" />
				</div>
				<div class="swiper-button-prev" />
				<div class="swiper-button-next" />
			</div>
		</div>
	</div>
</template>

<script>
import { Swiper, Navigation, Pagination } from 'swiper'

export default {
	name: 'ConsumerTutorialComponent',
	data() {
		return {
			currentSlide: 1
		}
	},
	mounted() {
		document.body.classList.add('disabled-scroll')
		const _this = this
		const swiper = new Swiper(this.$refs.swiper, {
			modules: [Navigation, Pagination],
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			on: {
				init() {
					_this.$emit('shown')
				}
			}
		})
		swiper.on('activeIndexChange', () => {
			this.currentSlide = swiper.activeIndex + 1
		})
	},
	computed: {
		messagesSlide() {
			return this.$config.privatesVisible
		}
	},
	methods: {
		closeTutorial() {
			this.$emit('close', this.currentSlide, false)
		},
		endTutorial() {
			this.$emit('end')
		}
	},
	beforeDestroy() {
		document.body.classList.remove('disabled-scroll')
	}
}
</script>

<style lang="scss" scoped>
.tutorial {
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);

	&-image {
		width: 100%;
		height: 100vh;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
}
::v-deep {
	.swiper-pagination-bullet {
		cursor: pointer;

		&:hover {
			background: rgba($color-white, 0.7);
		}
		&.swiper-pagination-bullet-active {
			pointer-events: none;
		}
	}
}
.consumer-tutorial {
	.swiper-button-next,
	.swiper-button-prev {
		top: 50%;
		z-index: 10;
		transform: translateY(-50%);
		width: 28px;
		height: 28px;
		border-radius: 50%;
		background: rgba($color-black-fifth, 0.5);
		transition:
			opacity 0.2s ease-in-out,
			background-color 0.2s ease-in-out;
		cursor: pointer;

		@media (max-width: $screen-desktop) {
			display: none;
		}

		&:hover {
			background: rgba($color-black-fifth, 0.7);
		}
		&:active {
			background: rgba($color-black-fifth, 0.5);
		}
		&.swiper-button-disabled {
			opacity: 0.5;
			pointer-events: none;
		}

		&:before {
			width: 7px;
			height: 7px;
		}
	}
	.swiper-button-prev {
		left: 32px;

		&:before {
			transform: translate3d(-35%, -50%, 0px) rotate(225deg);
		}
	}
	.swiper-button-next {
		right: 32px;

		&:before {
			transform: translate3d(-50%, -55%, 0px) rotate(45deg);
		}
	}
	&-slider {
		position: absolute;
		left: 50%;
		width: 100%;
		max-width: 520px;
		transform: translateX(-50%);
		top: 15%;
		bottom: 0;
		background-color: $color-purple;
		border-radius: 16px 16px 0 0;
		color: $color-white;

		@media (min-width: $screen-desktop) {
			top: 50%;
			transform: translate(-50%, -50%);
			max-width: 800px;
			border-radius: 16px;
			height: 85vh;
			max-height: 700px;
			bottom: auto;
		}

		.swiper {
			height: 100%;
		}
	}

	&-close {
		position: absolute;
		top: 16px;
		right: 20px;
		font-size: 28px;
		z-index: 10;
		color: $color-gray-additional;
		cursor: pointer;
		transition: $transition-color;
		@media (min-width: $screen-desktop) {
			right: -24px;
			top: -24px;

			svg {
				width: 18px;
				height: 18px;
			}
		}
		&:hover {
			color: $color-gray-additional-hover;
		}
		&:active {
			color: $color-gray-additional-active;
		}
	}

	&-text {
		max-width: 335px;
		text-align: center;
		font-size: 14px;
		line-height: 143%;
		min-height: 70px;
		margin: 0 auto;
		position: relative;
		z-index: 2;
	}

	&-img-wrap {
		position: relative;
		z-index: 1;
	}

	&-img {
		object-fit: contain;
		display: block;
		margin: 0 auto;
		max-height: 45vh;

		@media (min-width: $screen-desktop) {
			max-height: 50vh;
		}

		&.img-1 {
			max-width: 340px;
			max-height: 54vh;

			@media (max-height: 700px) {
				max-height: 45vh;

				@media (min-width: $screen-desktop) {
					max-height: 50vh;
				}
			}
		}
	}
	&-img-bg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 504px;
		height: 640px;
		background: url('@/assets/images/consumer-tutorial/bg.png') 50% 50% no-repeat;
		background-size: contain;
		z-index: -1;
	}

	&-slide {
		padding: 55px 20px;
		position: relative;
		overflow: hidden;
	}

	&-btn {
		display: flex;
		width: 100%;
		max-width: 220px;
		align-items: center;
		justify-content: center;
		color: $color-white;
		background-color: $color-magenta-secondary;
		height: 52px;
		border-radius: 12px;
		margin: 10px auto 0;
		font-weight: 700;
		position: relative;
		z-index: 10;
		cursor: pointer;
		transition:
			background-color 0.2s ease-in-out,
			opacity 0.2s ease-in-out;

		&:hover {
			background-color: $color-magenta-secondary-hover;
		}
		&:active {
			background-color: $color-magenta-secondary-active;
		}
	}
}

.swiper {
	&-controls {
		position: absolute;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%);
		height: 20px;
		z-index: 99999;

		@media (max-height: 700px) {
			bottom: 10px;
		}
	}
	&-button-next,
	&-button-prev {
		position: absolute;
		top: 0;
		height: 20px;
		width: 20px;
		&:before {
			content: '';
			position: absolute;
			border-top: 3px solid $color-white;
			border-right: 3px solid $color-white;
			width: 12px;
			height: 12px;
			top: 50%;
			left: 50%;
		}
	}
	&-button-prev {
		left: 0;
		&:before {
			transform: translate3d(-50%, -75%, 0px) rotate(225deg);
		}
	}
	&-button-next {
		right: 0;
		&:before {
			transform: translate3d(-50%, -75%, 0px) rotate(45deg);
		}
	}
	&-pagination {
		margin: 0 40px;
		display: flex;
		flex-flow: row nowrap;
		column-gap: 15px;
		z-index: 99999;
		align-items: center;
		height: 12px;
		&-bullet {
			width: 8px;
			height: 8px;
			border-radius: 20px;
			background-color: $color-semi-transparent;
			transition: all 0.3s linear;
			&-active {
				background-color: $color-white;
				width: 12px;
				height: 12px;
			}
		}
	}
}
</style>
