<template>
	<div class="network-error">
		<div class="network-error-text">{{ $t('networkError') }}</div>
	</div>
</template>

<script>
export default {
	name: 'NetworkErrorNotification'
}
</script>
