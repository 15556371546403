const baseMenuItems = [
	'author-schedule',
	'becomeAnConsumer',
	'consumer-subscriptions',
	'eula',
	'terms-of-service',
	'privacy-policy'
]

const menuList = {
	default: [...baseMenuItems, 'becomeAnAuthor', 'receipts', 'exit'],
	tele2ru: [...baseMenuItems, 'rules'],
	a1amdby: [...baseMenuItems, 'rules'],
	beelineru: [...baseMenuItems, 'exit']
}

const contentByTenant = {
	subscribeName: {
		a1amdby: 'favorite',
		default: 'subscribe'
	}
}

const redirectToProfileGirl = {
	beelineru: true,
	default: false
}

class AppConfig {
	constructor(tenant) {
		this.tenant = tenant || 'default'
		this.menuList = menuList[this.tenant] ?? menuList.default
		this.apiUrl = import.meta.env.VITE_API_URL
		this.authRedirectUrl = import.meta.env.VITE_AUTH_REDIRECT
		this.consumerLogoutUrl = import.meta.env.VITE_CONSUMER_LOGOUT_URL
		this.girlLogoutUrl = import.meta.env.VITE_GIRL_LOGOUT_URL
		this.subsCardUrl = import.meta.env.VITE_SUBS_CARD_URL
		this.subsUrl = import.meta.env.VITE_SUBS_URL
		this.tokenCookieName = import.meta.env.VITE_TOKEN_COOKIE_NAME
		this.maxImgSize = import.meta.env.VITE_MAX_IMG_SIZE_IN_BYTES
		this.minWithdrawAmount = import.meta.env.VITE_MIN_WITHDRAWAL_AMOUNT
		this.maxWithdrawAmount = import.meta.env.VITE_MAX_WITHDRAWAL_AMOUNT
		this.buildPwa = import.meta.env.VITE_BUILD_PWA === 'true'
		this.yaMetrikaId = import.meta.env.VITE_YA_METRIKA_ID
		this.scheduledPostsEnabled = import.meta.env.VITE_SCHEDULED_POSTS_ENABLED === 'true'
		this.reverbAppKey = import.meta.env.VITE_REVERB_APP_KEY
		this.reverbHost = import.meta.env.VITE_REVERB_HOST
		this.reverbPort = import.meta.env.VITE_REVERB_PORT
		this.reverbScheme = import.meta.env.VITE_REVERB_SCHEME
		this.broadcastingAuth = import.meta.env.VITE_BROADCASTING_AUTH
		this.sentryDSN = import.meta.env.VITE_SENTRY_DSN
		this.girlSubscriptionPrice = import.meta.env.VITE_GIRL_SUBSCRIPTION_PRICE
		this.girlSubscriptionPeriod = import.meta.env.VITE_GIRL_SUBSCRIPTION_PERIOD
		this.showSubscriptionPriceInGirlProfile = import.meta.env.VITE_SHOW_SUBSCRIPTION_PRICE_IN_GIRL_PROFILE
		this.subsModelLimit = Number(import.meta.env.VITE_SUBS_MODEL_LIMIT) || false
		this.redirectToProfileGirl = redirectToProfileGirl[this.tenant] ?? redirectToProfileGirl.default
		this.privatesVisible = import.meta.env.VITE_PRIVATES_VISIBLE === 'true'
		this.paidMessageEnabled = import.meta.env.VITE_PAID_MESSAGES_ENABLED === 'true'
		this.vapidPublicKey = import.meta.env.VITE_VAPID_PUBLIC_KEY
		this.guestAccess = import.meta.env.VITE_GUEST_ACCESS === 'true'
		this.guestAccessAuth = this.guestAccess ? undefined : true
		this.mailSupport = import.meta.env.VITE_MAIL_SUPPORT || 'support@amdigital.ru'
		this.metrikaSdpUserIdFeature = import.meta.env.VITE_METRIKA_SDP_USER_ID_FEATURE === 'true'
		this.allowDistributionMessage = import.meta.env.VITE_GIRL_DISTRIBUTION_MESSAGE_FEATURE === 'true'
		this.allowConsumerLists = import.meta.env.VITE_GIRL_CONSUMERS_LISTS_FEATURE === 'true'
		this.numberPostsViewed = 30
		this.feedShowPrivate = import.meta.env.VITE_FEED_SHOW_PRIVATE === 'true'
		this.isLocal = import.meta.env.VITE_IS_LOCAL === 'true'
		this.paidMessageFreeAccess = import.meta.env.VITE_PAID_MESSAGE_FREE_ACCESS === 'true'
		this.subscribeNameKey = contentByTenant.subscribeName[tenant] ?? contentByTenant.subscribeName.default
		this.chatGirlWelcomeMsg = import.meta.env.VITE_CHAT_GIRL_WELCOME_MSG === 'true'
		this.allowFollowing = import.meta.env.VITE_FOLLOWING === 'true'
	}

	subscribesTitleKey() {
		switch (this.tenant) {
			case 'a1amdby':
				return 'favorites'
			default:
				return 'subscribes'
		}
	}
}

export default new AppConfig(import.meta.env.VITE_TENANT)
