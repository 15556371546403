import { http } from '@/http/index'

async function consumerStartWaitingSubscription(code, tempHash) {
	return http.post(
		`/consumer/start-waiting-subscription/${code}`,
		{
			temp_hash: tempHash
		},
		{
			transformResponse(response, _, status) {
				if (status >= 400) {
					throw new Error('redirect')
				}
				return JSON.parse(response)
			}
		}
	)
}

export { consumerStartWaitingSubscription }
