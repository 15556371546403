<template>
	<dialog-component class="confirmation-dialog" @close="$emit('close')">
		<template #header-title>
			<slot name="title" />
		</template>
		<slot />
		<template #dialog-footer>
			<div class="confirmation-dialog-buttons">
				<ButtonComponent color="white" @click="$emit('decline')">
					{{ btnDeclineText }}
				</ButtonComponent>
				<ButtonComponent color="transparent" :loading="isSending" @click="$emit('accept')">
					{{ btnAcceptText }}
				</ButtonComponent>
			</div>
		</template>
	</dialog-component>
</template>

<script>
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

export default {
	name: 'ConfirmationDialog',
	components: { DialogComponent, ButtonComponent },
	props: {
		btnAcceptText: {
			type: String,
			default() {
				return this.$t('yes')
			}
		},
		btnDeclineText: {
			type: String,
			default() {
				return this.$t('no')
			}
		},
		isSending: {
			type: Boolean,
			default: () => false
		}
	}
}
</script>

<style lang="scss">
.confirmation-dialog {
	color: $color-gray;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;

	&-buttons {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
}
</style>
