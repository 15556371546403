import { defineStore } from 'pinia'
import { useGirlStore } from '@/stores/girl'
import { useHomeStore } from '@/stores/home'
import { useConsumerStore } from '@/stores/consumer'
import { usePostStore } from '@/stores/post'
import { useSearchStore } from '@/stores/search'
import { getMoreData } from '@/stores/helper'
import { useConsumerTutorialStore } from '@/stores/consumerTutorial'
import { useMessengerStore } from '@/stores/messenger'
import postsApi from '@/http/posts'
import consumerApi from '@/http/consumer'
import echo from '@/sockets/echo'

export const useProfileStore = defineStore('profile', {
	state: () => ({
		isProfileLoaded: false,
		balance: 0,
		id: null,
		nickname: '',
		moderation_nickname: '',
		about_yourself: '',
		avatar: '',
		avatar_id: null,
		cover: '',
		cover_id: null,
		msisdn: null,
		total_likes: 0,
		is_adult: false,
		total_subscriptions: 0,
		sms_notifications_social_on: null,
		followingGirls: null,
		has_subscription_to_service: false,
		notifications: 0,
		notificationList: null,
		subscriptionsData: null,
		hasCardPayment: null,
		isLoading: false,
		training_slider: null,
		show_privates: false,
		girl_subscription_restricted: false,
		modelStore: useGirlStore(),
		homeStore: useHomeStore(),
		consumerStore: useConsumerStore(),
		postStore: usePostStore(),
		searchStore: useSearchStore(),
		messengerStore: useMessengerStore()
	}),
	getters: {
		notificationsHasLoaded: (state) => state.notificationList !== null,
		followingGirlsHasLoaded: (state) => state.followingGirls !== null,
		subscriptionGirls: (state) => state.subscriptionsData?.data?.girls,
		serviceSubscriptionData: (state) => state.subscriptionsData?.data?.service,
		hasServiceSubscription: (state) =>
			state.subscriptionsData?.data?.service?.is_subscribed || state.subscriptionsData?.data?.service?.pending
	},
	actions: {
		async confirmAge() {
			try {
				this.isLoading = true
				await consumerApi.confirmAge()
				this.is_adult = true
			} finally {
				this.isLoading = false
			}
		},
		async getProfile() {
			const result = await consumerApi.getProfile()
			if (result) {
				Object.assign(this.$state, result)
				if (!echo.checkConnection()) {
					echo.connect()
				}
				if (!this.isProfileLoaded && this.show_privates) {
					echo.subscribe(this.id)
					this.messengerStore.getUnreadThreadsCount()
				}
				if (this.training_slider === 'onboarding') {
					useConsumerTutorialStore().setTutorial(this.training_slider)
				}
				await this.getFollowingGirls()
				this.isProfileLoaded = true
			}
		},
		async getFollowingGirls() {
			const result = await consumerApi.getFollowingGirls(this.id)
			if (result) {
				this.followingGirls = result
			}
		},
		async getNotifications() {
			this.isLoading = true
			const result = await consumerApi.getNotifications()
			if (result) {
				this.notifications = 0
				this.notificationList = result
			}
			this.isLoading = false
		},
		async getMoreNotifications() {
			await getMoreData(this.notificationList, consumerApi.getMoreNotifications)
		},
		async setLike(postId) {
			const result = await postsApi.like(postId)
			if (result) {
				const { girl, post, consumer } = result
				this.balance = consumer.balance_likes
				this.modelStore.updateLikes(girl, post)
				this.homeStore.updateLikes(post)
				this.consumerStore.updateLikes(post)
				this.postStore.incrementTotalLikes()
				this.searchStore.updateLikes(post)
			}
		},
		async updateProfile(formData) {
			this.isLoading = true
			const result = await consumerApi.updateProfile(formData)
			if (result) {
				Object.assign(this.$state, {
					about_yourself: formData?.about_yourself,
					moderation_nickname: formData?.nickname
				})
			}
			this.isLoading = false
		},
		async loadAvatar(formData) {
			this.isLoading = true
			try {
				const result = await consumerApi.loadAvatar(formData)
				Object.assign(this.$state, result)
				return result
				// eslint-disable-next-line
			} catch (error) {
				throw error
			} finally {
				this.isLoading = false
			}
		},
		async loadCover(formData) {
			this.isLoading = true
			try {
				const result = await consumerApi.loadCover(formData)
				Object.assign(this.$state, result)
				return result
				// eslint-disable-next-line
			} catch (error) {
				throw error
			} finally {
				this.isLoading = false
			}
		},
		async enableSmsNotification() {
			const result = await consumerApi.enableSmsNotification()
			if (result) {
				this.sms_notification_on = true
			}
		},
		async disableSmsNotification() {
			const result = await consumerApi.disableSmsNotification()
			if (result) {
				this.sms_notification_on = false
			}
		},
		async getSubscriptions() {
			try {
				this.isLoading = true
				this.subscriptionsData = await consumerApi.getSubscriptions()
			} finally {
				this.isLoading = false
			}
		},
		async getMoreSubscriptions() {
			await getMoreData(this.subscriptionsData, consumerApi.getSubscriptions)
		},
		removeSubscription(girlId) {
			this.subscriptionsData.data.girls = this.subscriptionsData.data.girls.filter(({ id }) => girlId !== id)
		},
		async unsubscribeFromService() {
			try {
				this.isLoading = true
				await consumerApi.unsubscribeFromService()
			} finally {
				this.isLoading = false
			}
		},
		async getSubscriptionTypes() {
			await this.getSubscriptions()
			const lastIndex = this.subscriptionsData?.meta?.last_page
			if (lastIndex === 1) {
				this.hasCardPayment = !!this.subscriptionsData?.data?.girls?.filter((e) => e.type === 'card').length
			}
			for (let i = 1; i <= lastIndex; i += 1) {
				const path = this.subscriptionsData?.meta?.path
				/* eslint-disable no-await-in-loop */
				const subsData = await consumerApi.getMoreSubscriptions(`${path}?page=${i}`)
				if (subsData?.data?.girls?.filter((e) => e.type === 'card').length) {
					this.hasCardPayment = true
					break
				} else if (i === lastIndex && !subsData?.data?.girls?.filter((e) => e.type === 'card').length) {
					this.hasCardPayment = false
				}
			}
			return null
		}
	}
})
