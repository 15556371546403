<template>
	<div
		@click="onSelect"
		class="history-item"
		:class="{
			active: filter?.currentGirl === id,
			opacity: waiting_subscription_result
		}"
	>
		<div class="image-container relative">
			<div class="image placeholder-bg">
				<img rel="preload" fetchpriority="high" :src="avatar" alt="" />
			</div>
			<div class="history-item-spinner" v-if="waiting_subscription_result">
				<i class="i-spinner rotating text-white" />
			</div>
			<template v-if="$config.allowFollowing">
				<badge-fan v-if="has_girl_subscription" />
				<badge-following v-else-if="has_girl_following && $config.allowFollowing" />
			</template>
		</div>
		<div class="history-item-name">{{ nickname }}</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useHomeStore } from '@/stores/home'
import BadgeFollowing from '@/components/BadgeFollowing.vue'
import BadgeFan from '@/components/BadgeFan.vue'

export default {
	name: 'FilterCard',
	components: { BadgeFan, BadgeFollowing },
	props: {
		nickname: { type: String, default: '' },
		avatar: { type: String, default: '' },
		id: { type: Number, default: 0 },
		waiting_subscription_result: { type: Boolean, default: false },
		has_girl_following: { type: Boolean, default: false },
		has_girl_subscription: { type: Boolean, default: false }
	},
	computed: {
		...mapState(useHomeStore, ['filter'])
	},
	methods: {
		...mapActions(useHomeStore, ['setFilter']),
		onSelect() {
			this.setFilter(this.id)
		}
	}
}
</script>

<style lang="scss" scoped>
.image-container {
	.badge-following,
	.badge-fan {
		position: absolute;
		right: -3px;
		bottom: -3px;
		border: 2px solid $color-black;
		box-sizing: content-box;
	}
}

.history-item .image {
	display: flex;
	overflow: hidden;
	justify-content: center;
}

.image img {
	height: 100%;
	object-fit: contain;
}

.history-item {
	@media (min-width: $screen-desktop) {
		cursor: pointer;
		transition: opacity 0.3s ease-in-out;
		&:hover {
			opacity: 0.7;
		}
	}
}
</style>
