<template>
	<div class="history-item opacity" @click="$emit('click')">
		<div class="history-item-btn flex center">
			<div class="history-item-add">+</div>
		</div>
		<div class="history-item-name">{{ $t('seeAll') }}</div>
	</div>
</template>

<script>
export default {
	name: 'FilterCardButton'
}
</script>
