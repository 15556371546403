import { http } from '@/http/index'

async function follow(girlId) {
	return http.post(`/girls/${girlId}/follow`)
}

async function unfollow(girlId) {
	return http.delete(`/girls/${girlId}/unfollow`)
}

export { follow, unfollow }
