<template>
	<dialog-component @close="closePWADialog">
		<template #header-title>
			{{ $t('pwa.addIcon') }}
		</template>
		<div class="dialog-flex">
			<img src="@/assets/images/logo-square.png" alt="My mood" width="40" height="40" />
			<p class="dialog-text">
				{{ $t('pwa.application') }}
			</p>
		</div>
		<div v-if="pwaText === 'safari'">
			<p class="dialog-text">
				{{ $t('pwa.pressIcon') }} <span class="icon-ios-setting" /> {{ $t('pwa.safariMenu') }}
				<span class="dialog-text--white">{{ $t('pwa.addHome') }}</span>
			</p>
		</div>
		<div v-if="pwaText === 'ios'">
			<p class="dialog-text">
				{{ $t('pwa.openSafari') }}
				<span class="icon-ios-setting" />, <span class="dialog-text--white">{{ $t('pwa.addHome') }}</span>
			</p>
		</div>
		<ButtonComponent v-if="pwaText === 'canPWAInstall'" @click="installPWA" color="white">
			{{ $t('add') }}
		</ButtonComponent>
	</dialog-component>
</template>

<script>
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useModalStore } from '@/stores/modal'
import { useCommonStore } from '@/stores/common'

export default {
	name: 'BankCardDialog',
	components: { DialogComponent, ButtonComponent },
	computed: {
		...mapWritableState(useCommonStore, ['deferredPrompt']),
		...mapState(useModalStore, ['pwaText'])
	},
	methods: {
		...mapActions(useModalStore, ['closePWADialog']),
		installPWA() {
			this.deferredPrompt.prompt()
			this.closePWADialog(true)
			this.deferredPrompt.userChoice.then((choiceResult) => {
				if (choiceResult.outcome === 'accepted') {
					this.$metrika.reachGoal('pwa-yes')
				} else {
					this.$metrika.reachGoal('pwa-no')
				}
			})
		}
	}
}
</script>
