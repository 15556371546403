export const createButtonTypes = {
	default: 'default',
	loading: 'loading',
	ready: 'ready'
}

export const complaintToModeratorText = 'complainToModerator'

export const subscriptionsParams = {
	likesForSubscription: 3,
	defaultSubscriptionsCount: 1
}

export const errorParams = {
	403: {
		message: 'error.tryLater',
		button: {
			action: 'mailToSupport',
			text: 'mailToSupport'
		}
	}
}
