import { defineStore } from 'pinia'
import messengerApi from '@/http/messenger'

export const useGuestMessengerStore = defineStore('guestMessenger', {
	state: () => ({
		messagesList: [],
		currentGirl: null,
		roomsList: []
	}),
	getters: {},
	actions: {
		getChatLocalStorage() {
			return localStorage.getItem('guest-chat') ? JSON.parse(localStorage.getItem('guest-chat')) : []
		},
		newGuestMessage(girl, msg, currentDate, welcomeMessage = false) {
			const arr = this.getChatLocalStorage()
			const found = arr?.chats?.some((el) => el.id === girl?.girlId)
			if (!found) {
				if (!arr.chats) {
					const item = {
						chats: []
					}
					Object.assign(arr, item)
				}
				arr.chats.push({
					id: girl?.girlId,
					nickname: girl?.nickname,
					avatar: girl?.avatar,
					messages: [
						{
							message: msg,
							created_at: currentDate,
							uid: welcomeMessage ? girl?.girlId : 0
						}
					]
				})
			} else {
				arr.chats
					.find((el) => el.id === girl?.girlId)
					.messages.push({
						message: msg,
						created_at: currentDate,
						uid: welcomeMessage ? girl?.girlId : 0
					})
			}
			localStorage.setItem('guest-chat', JSON.stringify(arr))
			this.messagesList.push({
				id: Date.now(),
				temporary_id: Date.now(),
				owner_id: welcomeMessage ? girl?.girlId : 0,
				body: msg,
				type_verbose: 'MESSAGE',
				created_at: currentDate,
				thread_id: currentDate
			})
		},
		checkWelcomeMessage(girlId) {
			const arr = this.getChatLocalStorage()
			const found = arr?.chats?.find((el) => el.id === girlId)?.messages?.some((el) => el.uid === girlId)
			if (!found) {
				return true
			}
			return false
		},
		async getWelcomeMessage() {
			const result = await messengerApi.getGuestWelcomeMessage(this.$config.isLocal)
			return result
		},
		getGuestMessages(girlId) {
			this.resetGuestMessages()
			const arr = this.getChatLocalStorage()
			const chats = arr?.chats?.find((el) => el.id === girlId)?.messages
			if (chats) {
				chats.forEach((item) => {
					this.messagesList.push({
						id: item.created_at,
						temporary_id: item.created_at,
						owner_id: item.uid,
						body: item.message,
						type_verbose: 'MESSAGE',
						created_at: item.created_at,
						thread_id: item.created_at
					})
				})
			}
		},
		resetGuestMessages() {
			this.messagesList = []
		},
		resetGuestRooms() {
			this.roomsList = []
		},
		getCurrentGirl() {
			const arr = this.getChatLocalStorage()
			this.currentGirl = arr.girl
		},
		setCurrentGirl(girl) {
			const arr = localStorage.getItem('guest-chat') ? JSON.parse(localStorage.getItem('guest-chat')) : { girl: [] }
			arr.girl = {
				girlId: girl?.id,
				nickname: girl?.nickname,
				avatar: girl?.avatar
			}
			localStorage.setItem('guest-chat', JSON.stringify(arr))
			this.currentGirl = arr.girl
		},
		async saveGuestChats(data) {
			try {
				await messengerApi.saveGuestMessages(data)
			} finally {
				localStorage.removeItem('guest-chat')
			}
		},
		async initRoomsList() {
			this.resetGuestRooms()
			this.roomsList.push({
				init: true,
				id: Date.now(),
				type: 1,
				type_verbose: 'PRIVATE',
				name: this.currentGirl?.nickname,
				avatar: {
					sm: this.currentGirl?.avatar
				},
				resources: {
					latest_message: {
						id: Date.now(),
						thread_id: Date.now(),
						owner_id: 0,
						type: 0,
						type_verbose: 'MESSAGE',
						body: 'message',
						created_at: Date.now(),
						updated_at: Date.now()
					}
				}
			})
		},
		getRoomsList() {
			const arr = this.getChatLocalStorage()
			this.resetGuestRooms()
			if (arr.chats) {
				arr.chats.forEach((item) => {
					this.roomsList.push({
						id: item?.id,
						type: 1,
						type_verbose: 'PRIVATE',
						name: item?.nickname,
						avatar: {
							sm: item?.avatar
						},
						resources: {
							latest_message: {
								id: item?.messages.at(-1)?.uid,
								thread_id: item?.messages.at(-1)?.created_at,
								owner_id: 0,
								type: 0,
								type_verbose: 'MESSAGE',
								body: item?.messages.at(-1)?.message,
								created_at: item?.messages.at(-1)?.created_at,
								updated_at: item?.messages.at(-1)?.created_at
							}
						}
					})
				})
				this.roomsList.reverse()
			}
		}
	}
})
