import { defineStore } from 'pinia'
import { follow, unfollow } from '@/http/consumer'

export const useFollowingApiStore = defineStore('followingApiStore', {
	actions: {
		startFollowing(girlId) {
			return follow(girlId)
		},
		stopFollowing(girlId) {
			return unfollow(girlId)
		}
	}
})
