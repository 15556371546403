<template>
	<dialog-component @close="closeCreatePostDialog">
		<template #header-title>
			{{ $t('post.loading') }}
		</template>
		<template #header-icon><i class="i-arrow-left arrow-down" /></template>
		<p class="upload-processing-text">
			{{ $t('post.loadingWait') }}
		</p>
		<ButtonComponent color="primary-outline" @click="cancelUploadClick">
			{{ $t('post.loadingCancel') }}
		</ButtonComponent>
	</dialog-component>
</template>

<script>
import { mapActions } from 'pinia'
import { useVideoUploadStore } from '@/stores/videoUpload'
import { useCreatePostStore } from '@/stores/createPost'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

export default {
	name: 'CreatePostLoaderDialog',
	components: { DialogComponent, ButtonComponent },
	methods: {
		...mapActions(useCreatePostStore, ['closeCreatePostDialog']),
		...mapActions(useVideoUploadStore, ['cancelUpload']),
		cancelUploadClick() {
			this.cancelUpload()
			this.closeCreatePostDialog()
		}
	}
}
</script>

<style lang="scss" scoped>
.upload-processing-text {
	font-size: 16px;
	line-height: 20px;
	color: $color-gray;
	margin-bottom: 16px;
}

.title-left {
	margin-left: 0;
}

.arrow-down {
	font-size: 16px;
	transform: rotate(-90deg);
	color: $color-white;
	transition: $transition-color;
	&:hover {
		color: $color-white-hover;
	}
	&:active {
		color: $color-white-active;
	}
}
</style>
