import { defineStore } from 'pinia'
import isMobile from 'ismobilejs'

function getIsMobileDevice() {
	const { userAgent } = navigator
	return isMobile(userAgent).any
}

export const useCommonStore = defineStore('common', {
	state: () => ({
		isPowerSaveMode: false,
		deferredPrompt: null,
		tenant: import.meta.env.VITE_TENANT,
		isMobileDevice: getIsMobileDevice(),
		isLoading: false,
		locale: 'ru'
	}),
	actions: {
		setDeferredPrompt(e) {
			this.deferredPrompt = e
		},
		removeDeferredPrompt() {
			this.deferredPrompt = null
		},
		setIsLoadingState(state) {
			this.isLoading = state
		},
		setLocale(locale) {
			this.locale = locale
		}
	}
})
