<template>
	<span class="badge-fan">
		<svg-icon name="star" size="9px" />
	</span>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'

export default {
	name: 'BadgeFan',
	components: {
		SvgIcon
	}
}
</script>

<style scoped lang="scss">
.badge-fan {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color-white;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background: $color-blue-dark;
	margin-left: 5px;
}
</style>
