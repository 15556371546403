<template>
	<dialog-component @close="$emit('close')">
		<template #header-title>{{ title }}</template>
		<div class="create-post-error">
			<p>{{ message }}</p>
			<p>{{ description }}</p>
		</div>
	</dialog-component>
</template>

<script>
import DialogComponent from '@/components/dialogs/DialogComponent.vue'

export default {
	name: 'ValidationErrorDialog',
	components: { DialogComponent },
	props: {
		message: {
			type: String,
			default: ''
		},
		description: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default() {
				return this.$t('notFit')
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.create-post-error {
	font-size: 16px;
	line-height: 20px;
	color: $color-gray;
}
</style>
