<template>
	<dialog-component class="sub-limit-error-dialog" @close="$emit('close')">
		<template #header-title>
			{{ $t('subscription.limit') }}
		</template>
		<div class="sub-limit">
			<div class="top-section">
				<div
					class="user-avatar"
					:style="{ 'background-image': `url('${profile?.avatar || subLimitModalGirl?.avatar}')` }"
				/>
				<div class="user-descr">
					{{ $t('subscription.tryLater') }}
					<router-link @click.native="$emit('close')" :to="{ name: 'rules', hash: '#subs_restrictions' }">
						{{ $t('subscription.details') }}
					</router-link>
				</div>
			</div>
		</div>
		<template #dialog-footer>
			<ButtonComponent color="white" @click="$emit('decline')">
				{{ $t('clear') }}
			</ButtonComponent>
		</template>
	</dialog-component>
</template>

<script>
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import { mapState } from 'pinia'
import { useGirlStore } from '@/stores/girl'
import { useModalStore } from '@/stores/modal'

export default {
	name: 'SubLimitErrorDialog',
	components: { DialogComponent, ButtonComponent },
	computed: {
		...mapState(useGirlStore, { profile: 'profile' }),
		...mapState(useModalStore, ['subLimitModalGirl'])
	}
}
</script>

<style lang="scss" scoped>
.sub-limit-error-dialog {
	color: $color-gray;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	& .sub-limit {
		& .top-section {
			display: flex;
			& .user-avatar {
				margin-right: 10px;
			}
			& .user-descr {
				color: $color-black;
				background-color: $color-gray-light-additional-third;
				padding: 4px 8px;
				border-radius: 3px 12px 12px 12px;
				& a {
					display: block;
					color: $color-black;
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
}
</style>
